import axios from "./axios";
//auth
export const login = (data) =>
  axios({
    method: "post",
    url: `/login`,
    data: data,
  });

//medical center
export const getMedicalCenters = (page) =>
  axios({
    method: "get",
    url: `/medical-centers`,
    params: {
      page,
    },
  });
export const showMedicalCenter = () =>
  axios({
    method: "get",
    url: `/profile`,
  });

export const createMedicalCenter = (data) =>
  axios({
    method: "post",
    url: `/medical-centers`,
    data,
  });

export const updateMedicalCenter = (data) =>
  axios({
    method: "post",
    url: `/profile`,
    data,
  });

export const deleteMedicalCenterImage = (id) =>
  axios({
    method: "delete",
    url: `/profile/images/${id}`,
  });
export const deleteMedicalCenter = (id) =>
  axios({
    method: "delete",
    url: `/medical-centers/${id}`,
  });

//doctor
export const getDoctors = (page, medical_center_id, params) =>
  axios({
    method: "get",
    url: `/doctors`,
    params: {
      page,
      medical_center_id,
      ...params,
    },
  });
export const showDoctor = (id) =>
  axios({
    method: "get",
    url: `/doctors/${id}`,
  });

export const createDoctor = (data) =>
  axios({
    method: "post",
    url: `/doctors`,
    data,
  });

export const updateDoctor = (id, data) =>
  axios({
    method: "put",
    url: `/doctors/${id}`,
    data,
  });
export const updateDoctorFees = (id, data) =>
  axios({
    method: "put",
    url: `/doctors/${id}/update-fees`,
    data,
  });

export const deleteDoctor = (id) =>
  axios({
    method: "delete",
    url: `/doctors/${id}`,
  });

export const deleteClinicImage = (doctor_id, id) =>
  axios({
    method: "delete",
    url: `/doctors/${doctor_id}/gallery/${id}`,
  });

//governorates
export const getGovernorates = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/governorates`,
    params: {
      page,
      pagination,
    },
  });

//cities
export const getCities = (page, governorate_id, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/cities`,
    params: {
      page,
      governorate_id,
      pagination,
    },
  });

// insurance-companies
export const getCompanies = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/insurance-companies`,
    params: {
      page,
      pagination,
    },
  });

// professional-titles
export const getProfessionalTitles = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/professional-titles`,
    params: {
      page,
      pagination,
    },
  });

// titles
export const getTitles = (page) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/titles`,
    params: {
      page,
    },
  });

// languages
export const getLanguages = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/languages`,
    params: {
      page,
      pagination,
    },
  });

// medical-specialties
export const getMedicalSpecialties = (pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/medical-specialties`,
    params: {
      pagination,
    },
  });

// lab service
export const getLabSpecialties = (page) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/lab-specialties`,
    params: {
      page,
    },
  });

// home nursing
export const getHomeNursingList = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/nursing-specialities`,
    params: {
      page,
      pagination,
    },
  });

// offer specialties
export const getOffersSpecialtiesList = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/offer-specialties`,
    params: {
      page,
      pagination,
    },
  });

// home vaccination
export const getHomeVaccinationList = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/vaccinations`,
    params: {
      page,
      pagination,
    },
  });
// sub specialties
export const getSubLabSpecialties = (page, medical_specialty_id, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/sub-medical-specialties`,
    params: {
      page,
      medical_specialty_id,
      pagination,
    },
  });

// doctor-appointments
export const getAppointments = (doctor_id) =>
  axios({
    method: "get",
    url: `/${doctor_id}/doctor-appointments`,
    params: {
      today_more: true,
    },
  });
export const showAppointment = (id) =>
  axios({
    method: "get",
    url: `/doctor-appointments/${id}`,
  });
export const createAppointment = (doctor_id, data) =>
  axios({
    method: "post",
    url: `/${doctor_id}/doctor-appointments`,
    data,
  });

export const deleteAppointment = (doctor_id, id) =>
  axios({
    method: "delete",
    url: `/${doctor_id}/doctor-appointments/${id}`,
  });

export const createMultipleAppointments = (doctor_id, data) =>
  axios({
    method: "post",
    url: `/${doctor_id}/doctor-appointments/store-bulk-array`,
    data,
  });

export const getReservations = (page, date, doctor_id) =>
  axios({
    method: "get",
    url: `/reservations`,
    params: {
      page,
      date,
      doctor_id,
    },
  });

export const showReservation = (id) =>
  axios({
    method: "get",
    url: `/reservations/${id}`,
  });
export const updateReservationAttendance = (id, data) =>
  axios({
    method: "put",
    url: `/reservations/${id}/update-status`,
    data,
  });

//patients
export const getPatients = (page, filters) =>
  axios({
    method: "get",
    url: `/patients`,
    params: {
      page,
      ...filters,
    },
  });

export const getPatientMedicalFile = (id) =>
  axios({
    method: "get",
    url: `/patients/${id}/show-profile`,
  });

//statistics
export const getStatistics = () =>
  axios({
    method: "get",
    url: `/statistics`,
  });

//offers
export const getOffers = (page, { admin_status }) =>
  axios({
    method: "get",
    url: `/offers`,
    params: {
      page,
      admin_status,
    },
  });
export const showOffer = (id) =>
  axios({
    method: "get",
    url: `/offers/${id}`,
  });

export const createOffer = (data) =>
  axios({
    method: "post",
    url: `/offers`,
    data,
  });

export const updateOffer = (id, data) =>
  axios({
    method: "put",
    url: `/offers/${id}`,
    data,
  });
export const deleteOffer = (id) =>
  axios({
    method: "delete",
    url: `/offers/${id}`,
  });

export const deleteOfferImage = (offer_id, id) =>
  axios({
    method: "delete",
    url: `/offers/${offer_id}/gallery/${id}`,
  });

// notifications
export const getNotifications = () =>
  axios({
    method: "get",
    url: `/notifications`,
  });

export const getUnreadNotificationsCount = () =>
  axios({
    method: "get",
    url: `/notifications/unread-count`,
  });

export const markNotificationAsRead = () =>
  axios({
    method: "post",
    url: `/notifications/mark-read`,
  });
